.search input {
  padding: 0.5rem 1rem;
  border: 1px solid #757575;
  width: 100%;
  border-radius: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.drawer_right {
  animation: drawer 0.2s;
  animation-timing-function: linear;
  animation-delay: 0.1ms;
}

.btn_outline {
  border: 2px solid orangered !important;
  color: orangered;
}

body {
  overflow-x: hidden !important;
}

.ProductScreen .row {
  --bs-gutter-x: 0rem !important;
}

.gutter {
  --bs-gutter-x: 0rem !important;
}

input {
  outline: none !important;
}

.form-check-input:checked {
  background-color: orangered !important;
  border-color: orangered !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(135, 60, 5);
}

input:focus {
  border-bottom: 1px solid orangered !important;
}

.rotating-icon {
  animation: rotate .5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: scale(0);
  }

  33% {
    transform: rotate(1);
  }

  66% {
    transform: rotate(0.5);
  }

  100% {
    transform: rotate(1);
  }
}


@keyframes drawer {
  from {
    width: 0;
  }

  to {
    width: 325px;
  }
}

.search i {
  color: #757575;
}

.nav_items {
  list-style: none;
  margin-bottom: 0;
}

.nav_items li {
  border-right: 1px solid gray;
}

.nav_items li span i {
  font-size: 1.2rem;
}

.nav_items span span {
  font-size: 1.1rem;
}

.card .card-body .title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.login_card input {
  border: none;
  border-bottom: 1px solid gray;
  width: 100%;
  outline: none;
}

.hover_box {
  display: none;
}

.hover_box button:active+.hover_box {
  height: 0;
  display: none !important;
}

.icon_hover:hover .hover_box {
  display: inline-block;
}

.icon_hover:hover {
  color: orangered;
}

.btn_profile {
  margin-bottom: 1rem !important;
  position: relative;
  top: 0;
  display: inline-block;
  width: 100%;
  padding: 0.2rem;
  font-weight: 500;
}

.btn_profile::after {
  content: "";
  border-radius: 20px;
  background: orangered;
  display: inline-block;
  height: 2px;
  width: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: 0.4s all linear;
  transform: translate(-50%, 0%);
}

.btn_profile:hover.btn_profile::after {
  width: 50% !important;
}

.btn_profile:hover {
  color: orangered;
}

.product_Detail_img {
  width: 100%;
  height: 300px;
}

.section {
  margin: 50px 0px;
}

.btn {
  border: none;
  box-shadow: none !important;
}

.btn-primary {
  background: orangered !important;
  color: white !important;

}

.cart_link:hover {
  color: orangered;
  cursor: pointer;
}

.search {
  width: 500px;
}

.bt-primary {
  background-color: #9f2089 !important;
  color: white !important;
}

.cart_item_img {
  width: 200px;
  height: 130px;
}

.cart_item_img2 {
  width: 130px;
  height: 130px;
}



.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: #ffffff7d;
}


.loader::after {
  content: "";
  width: 0;
  height: 100vh;
  background: white;
  display: inline-block;
  position: absolute;
  top: 0;
  animation: screenEffect 0.2s;
  animation-timing-function: linear;
  left: 0;
}

.loader::before {
  content: "";
  width: 0;
  height: 100vh;
  background: white;
  display: inline-block;
  position: absolute;
  top: 0;
  animation: screenEffect 0.2s;
  animation-timing-function: linear;
  right: 0;
}

.hover_box {
  transition: 0.3s all ease-in-out;
}

@keyframes screenEffect {
  from {
    width: 50%;
  }

  to {
    width: 0px;
  }
}

@media screen and (max-width:400px) {
  .product_Detail_img {
    height: 180px !important;
  }
}

@media screen and (max-width:678px) {
  .hover_box {
    left: -10rem !important;
    width: 200px !important;
  }
}

@media screen and (max-width:575px) {
  header {
    justify-content: space-between;
  }

  .search {
    display: none;
  }

  .row a {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .card {
    border-radius: 0;
  }

  .card img {
    border-radius: 0 !important;
  }

  .login_card {
    width: 100% !important;
  }

  .cart_container>.row>div {
    padding: 0 !important;
    border: none;
  }

  .cart_container>.card {
    margin: 0 !important;
  }
}

@media screen and (max-width:998px) {
  .nav_items>li:first-child {
    display: none !important;
  }


}

@media screen and (max-width:1200px) {
  .search {
    width: 350px;
  }
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

/* HomeSCreen  */

.Home_img {
  position: relative;
  background-image: url(/public/assets/banner_30.01.2024_1400x.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 100%;
}

.home_content {
  position: absolute;
  bottom: 5%;
  left: 25%;
  color: white;
}

.Part {
  position: relative;
  height: 400px;
  overflow: hidden;
  transition: all .5s linear;
}

.Part_content {
  position: absolute;
  bottom: 1%;
  left: 5%;
  color: white;
}

.Part_img {
  background-image: url(/public/assets/DSC_0248-4-scaled.webp);
  background-size: cover;
  width: 100%;
}

.Part_img2 {
  background-image: url(/public/assets/lose-weight-tea-GQ.webp);
  background-size: cover;
  background-position: 80%;
  width: 100%;
}

.Part:hover {
  transform: scale(1.05);
}



/* Contact Screen  */

.Contact {
  position: relative;
  background-image: url(/public/assets/main.png.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 90%;
  width: 100%;
  height: 400px;
}

.Contact_content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* About Screen  */

.About_img {
  position: relative;
  background-image: url(/public/assets/DSC_0248-4-scaled.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  width: 100%;
  height: 400px;
}

.About_content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}



/* Profile Screen  */
.Profile_img {
  position: relative;
  background-image: url('/public/assets/tea_profile.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Profile_content {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 2rem;
  width: 90%;
  max-width: 800px;
  color: white;
  border: 2px solid white;
}

.front {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: .5rem;
  width: 90%;
  max-width: 800px;
  margin-top: 20px;
  text-align: center;
  color: white;
  font-size: 20px;
  border: 2px solid white !important;
  transition: all .5s linear;
}

.front:hover {
  background-color: orangered !important;
  color: white !important;
}

@media (max-width: 768px) {
  .Profile_content {
    padding: 1.5rem;
    font-size: 0.9rem;
  }
}